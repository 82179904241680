.data-section {
    margin-top: 80px;
    .flex-center {
        margin-top: 40px;
        display: flex;
        align-items: center;
    }

    .safe-img {
        height: 400px;
        width: 100%;
    }

    .list {
        .item {
            display: flex;
            align-items: center;
            margin-bottom: 80px;
            img {
                height: 30px;
                width: 30px;
                margin-right: 18px;
            }
            h2 {
                margin: 0;
                font-size: 16px;
                letter-spacing: 3px;
                color: var(--white);
                font-weight: 400;
                font-family: "ChakraPetchItalic";
            }
        }
    }
}
