.info-section {
  margin-top: 40px;

  .hand-with-img {
    height: 650px;
    width: 100%;
    object-fit: contain;
  }

  .img-container {
    padding-top: 100px;
    position: relative;
    background: url("../../../assets/images/Layer.svg") no-repeat center center fixed; //fixed
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow: hidden;

    .gradient-top {
      top: 0;
      width: 100%;
      position: absolute;
      transform: rotate(180deg);
    }

    .gradient-bottom {
      width: 100%;
      position: absolute;
      bottom: 0;
    }
  }

  .container {
    margin-top: 50px;
    text-align: center;
    .sm-txt {
      font-size: 16px;
      font-family: 'ChakraPetchRegular';
      color: var(--white);
    }
    .lg-txt {
      font-family: 'ChakraPetchBold';
      font-size: 28px;
      color: var(--white);
    }
    .tile-logo {
      height: 40px;
      width: 40px;
    }
    .description {
      margin-top: 20px;
      text-align: start;
    }
  }

  .hand-img {
    height: 490px;
    width: 100%;
    object-fit: contain;
  }

  .cube-icon {
    height: 40px;
    width: 40px;
  }
}

.buttons {
  display: flex;
  justify-content: space-around;
}