.proven-team-container {
    text-align: center;
    .small-label {
        font-size: 16px;
        font-family: "Myriad Pro";
        color: rgb(255, 255, 255);
        margin-bottom: 0;
    }
    .heading {
        font-size: 39px;
        color: #00d1e9;
        font-style: italic;
        font-weight: 600;
        font-family: "ChakraPetchItalic";
    }
    .desc {
        font-size: 18px;
        font-family: "Myriad Pro";
        color: rgb(255, 255, 255);
        margin-bottom: 16px;
    }
    .card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 180px;
        width: 250px;
        margin: auto;
        &.first-card{
            background: url("../../../assets/images/team-card-1.png") no-repeat;
            background-size: contain;

        }
        &.second-card{
            background: url("../../../assets/images/team-card-2.png") no-repeat;
            background-size: contain;

        }
        &.third-card{
            background: url("../../../assets/images/team-card-3.png") no-repeat;
            background-size: contain;

        }
        &-title {
            font-size: 54px;
            color: rgb(255, 255, 255);
            line-height: 0.875;
            text-align: center;
            font-style: italic;
            font-weight: 600;
            font-family: "ChakraPetchItalic";
        }
        &-description {
            font-size: 22px;
            color: rgb(255, 255, 255);
            line-height: 22px;
            text-align: center;
            font-style: italic;
            font-family: "ChakraPetchItalic";
        }
    }
}
