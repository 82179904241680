.earn-section {
  margin-top: 80px;
  .list {
      .item {
        text-align: start;
        margin-bottom: 20px;
          .align-center {
            display: flex;
            align-items: center;      
          } 

          img {
              height: 40px;
              width: 40px;
              font-family: 'ChakraPetchRegular';
          }
          h2 {
              margin: 0;
              font-size: 18.8px;
              letter-spacing: 6px;
              color: var(--white);
              font-weight: 600;
              font-family: "Chakra Petch";
              font-family: 'ChakraPetchRegular';
          }
          p {
              margin: 0;
              font-size: 13.6px;
              letter-spacing: 3px;
              color: var(--white);
              font-weight: 400;
              font-family: 'ChakraPetchRegular';
          }
      }
  }

  .barcode-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--white);
    padding: 30px 0;

    h2 {
        font-size: 18px;
        letter-spacing: 4px;
        color: #000000;
        font-weight: 400;
        font-family: "Chakra Petch";
        text-align: center;
    }

    .barcode-img {
        height: 220px;
        width: 220px;
    }
  }
}