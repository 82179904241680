@font-face {
  font-family: "ChakraPetchMedium";
  src: url("./assets/fonts/ChakraPetch-Medium.ttf");
}

@font-face {
  font-family: "ChakraPetchBold";
  src: url("./assets/fonts/ChakraPetch-Bold.ttf");
}

@font-face {
  font-family: "ChakraPetchRegular";
  src: url("./assets/fonts/ChakraPetch-Regular.ttf");
}

@font-face {
  font-family: "ChakraPetchItalic";
  src: url("./assets/fonts/ChakraPetch-Italic.ttf");
}
@font-face {
  font-family: "Myriad Pro";
  src: url("./assets/fonts/MYRIADPRO-REGULAR.OTF");
}

.section-title > .subtitle {
  font-size: 10.8px;
  letter-spacing: 3px;
  color: var(--white);
  font-weight: 400;
  font-family: "Chakra Petch";
  text-align: center;
}
.section-title > .title {
  font-size: 27px;
  letter-spacing: 3px;
  color: #00e6f2;
  font-weight: 400;
  font-family: "ChakraPetchItalic";
  text-align: center;
}

.pad-24 {
  padding: 24px;
}

.pad-l-r-24 {
  padding: 0 24px;
}

.pad-l-r-40 {
  padding: 0 40px;
}

.custom-button {
  width: 220px;
  height: 40px;
  color: var(--black);
  border-radius: 0 0 270px 0;
  background-image: linear-gradient(90deg, #00d1e9 0%, #00e9f3 100%);
  text-align: center;
}

.desc {
  margin-top: 20px;
}

.desc-txt {
  margin-top: 20px;
  font-size: 12px;
  letter-spacing: 1px;
  color: #7f7f7f;
  font-family: "Arial";
  text-align: justify;
  width: 90%;
  margin: auto;
  margin-top: 40px;
}

body {
  margin: 0;
}
.outer {
  text-align: center;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}
.home-top-video:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(
    to right,
    rgba(65, 0, 255, 0.4),
    rgba(255, 0, 232, 0.3)
  );
}

.home-top-video {
  left: 0%;
  top: 0%;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.home-text {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  z-index: 1;
}

@media screen and (max-width: 1023px) {
  .overlay-content {
    padding-top: 40px !important;
  }
  video {
    object-fit: contain !important;
  }

  .mini-icon {
    height: 120px !important;
    width: 120px !important;
  }

  .overlay-content img {
    height: 101px !important;
  }

  .hand-with-img {
    height: auto !important;
  }

  .hand-img {
    margin-top: 2rem;
    height: auto !important;
  }

  .social-icons-container {
    padding-bottom: 70px !important;
  }

  .social-icons {
    width: 90% !important;
  }
  
  .data-section .list .item h2 {
    font-size: 13px !important;
    letter-spacing: 1px !important;
  }

  .data-section .list .item img {
    height: 20px !important;
    width: 20px !important;
  }

  .data-section .list .item {
    margin-bottom: 20px !important;
  }

  .section-title > .subtitle {
    font-size: 12px !important;
    letter-spacing: 1px !important;
  }

  .section-title > .title {
    font-size: 24px !important;
    letter-spacing: 1px !important; 
  }

  .earn-section {
    margin-top: 20px !important;
  }

  .pad-l-r-40 {
    padding: 16px !important;
  }

  .right-card {
     margin: 0px !important;
  }
}

.site-layout-background {
  background: var(--black);
}