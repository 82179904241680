.main-container {
  padding-bottom: 60px;
}
.social-icons {
  width: 350px;
  margin-top: 20px;
  object-fit: contain;
}
.chart-section {
  margin-top: 4rem;
  margin-bottom: 4rem;
  .img-container {
    background: url("../../assets/images/tokenomics-effect.png") no-repeat center center fixed;
    background-size: cover;
    overflow: hidden;
    min-height: 400px;
  }
}
