.slider {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  img {
      height: 120px;
      width: 120px;
      object-fit: contain;

      &.mini-icon {
        height: 80px;
        width: 80px;
      }
  }
}