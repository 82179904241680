.roadmap-container {
    text-align: center;
    .small-label {
        font-size: 16px;
        font-family: "Myriad Pro";
        color: rgb(255, 255, 255);
        margin-bottom: 0;
    }
    .heading {
        font-size: 39px;
        color: #00d1e9;
        font-family: "ChakraPetchItalic";
        font-style: italic;
        font-weight: 600;
    }
    .description {
        .line {
            font-size: 16px;
            font-family: "Myriad Pro";
            color: rgb(255, 255, 255);
            line-height: 1.75;
            .link {
                color: rgb(255, 255, 255);
                text-decoration: underline;
            }
        }
    }
    .timeline-container {
        .ant-timeline {
            color: white;
            &-item {
                padding-bottom: 115px;
                &-tail {
                    border-color: #05e4f0;
                    box-shadow: 0 4px 16px #05e2e6, 0 4px 16px #05e4e8;
                }
                &-head-blue {
                    height: 20px;
                    width: 20px;
                    background: #05e4f0;
                    border: none;
                    margin-left: -9px;
                }
                &-label {
                    font-size: 28px;
                    color: #05e4f0;
                    font-family: "ChakraPetchItalic";
                    font-weight: 600;
                    padding: 0 3rem;
                }
            }
            .first-item{
                padding-bottom: 60px;
                .ant-timeline-item-head{
                    display: none;
                }
            }
            .custom-item-padding{
                padding-bottom: 60px;
            }
        }
        .card {
            max-width: 300px;
            min-height: 125px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #181818;
            margin-bottom: 10px;
            border-radius: 5px;
            padding: 10px;
            border: 1px solid #05e4f0;
            color: #b9b9b9;
            text-align: center;
            font-family: "ChakraPetchRegular";
        }
        .left-card {
            margin: auto;
            margin-left: 20px;
        }
        .date {
            text-align: initial;
            font-size: 28px;
            color: #05e4f0;
            font-family: "ChakraPetchItalic";
            font-weight: 600;
        }
        .right-card {
            margin: auto;
            margin-right: 20px;
        }
        .future-timeline-item {
            .ant-timeline-item-content {
                margin: auto;
            }
            .future {
                color: #181818;
                height: 32px;
                width: 180px;
                background: #05e4f0;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto;
                font-style: italic;
                font-family: "ChakraPetchBold";
                font-size: 18px;
            }
        }
    }
}
