.bg-video-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}
video {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100vh;
  z-index: 1;
  object-fit: unset;
}
.overlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 48%);
  background-size: 3px 3px;
  z-index: 2;
}
.overlay-content {
  padding-top: 140px;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 3;

  img {
    height: 70px;
  }

  .description-txt {
    font-size: 18;
    letter-spacing: 1px;
    color: var(--white);
    font-weight: 400;
    font-family: "ChakraPetchMedium";
    text-align: center;
  }

  .center-txt {
    font-size: 38px;
    letter-spacing: 3px;
    color: var(--white);
    font-weight: 700;
    font-family: "ChakraPetchBold";
  }

  .input-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .custom-input {
      width: 220px;
      height: 40px;
    }

    .custom-button {
      margin-top: 8px;
    }
  }
}

.btn {
  margin-top: 10px;
  background: none;
  border-image: linear-gradient(160deg, #00d1e9 85%, transparent 15%) 13
    fill/13px;
  width: 220px;
  height: 40px;
  color: black;
  font-family: "ChakraPetchBold";

  &:hover {
    opacity: 0.8;
  }
}

.btn-2 {
  margin-top: 10px;
  background: none;
  border-image: linear-gradient(200deg, #00d1e9 85%, transparent 15%) 13
    fill/13px;
  width: 220px;
  height: 40px;
  color: black;
  font-family: "ChakraPetchBold";

  &:hover {
    opacity: 0.8;
  }
}